import React, { Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CustomerInteractionAction from "../CustomerInteractionActions";
import ProcessUpdateForm from "./ProcessUpdateForm/index";
import LiveTracking from "./LiveTracking/index";
import Feedback from "./Feedback/index";
import EventScreen from "./EventScreen/index";
import NotFound from "../components/FailedMessage";
import Expire from "../components/ExpireMessage";
import { isEmpty } from "loadsh";
import TableLoader from "../components/TableLoader";

import { containerConstantsService } from "../Utils/containerConstants/index";
import countryNamesAndCodes from "../Utils/countryConstants";
import Header from "../components/Header";
import HelpSection from "../components/HelpSection/index";
import queryString from "query-string";
import ProcessUpdateFormService, {
  processUpdateFormService,
} from "../services/ProcessUpdateFormService";

import LayoutScreen from "./LayoutScreen/index";
import DeskTopView from "./DesktopView/index";
import AvailableLocation from "./AvailableLocation";
import { getPudoData } from "./MapView/MapViewAction";

import { MyContext } from "./context/index";
import { storeClickCountService } from "../StoreClickCountService";
import AvailableLocationV3 from "./AvailableLocationV3";
import AvailableLocationV3Desktop from "./AvailableLocationV3Desktop";

import MessageSnackBar from "../components/MessageSnackBar";
import * as CiDiyActions from "./DIY/service/ciDiyActions";
import { getThemeFromDiyConfig } from "./DIY/service/ciDiyActions";
import { Skeleton } from "antd";
import { commonUtilities } from "../services/commonUtilities";
import DesktopViewPrototype from "./DesktopViewPrototype";
import MobileViewPrototype from "./MobileViewPrototype";
import DesktopViewOrder from "./DesktopViewOrder/index";
import MobileViewOrder from "./MobileViewOrder/index"
import DesktopInt from "./DesktopInt";
import DeviceInt from "./DeviceInt";
import NewMobileViewOrder from "./MobileViewOrder/NewMobileViewOrder";
import NewDesktopViewOrder from "./DesktopViewOrder/NewDesktopViewOrder";



class Screen extends React.Component {
  merchantCode;
  deviceType;
  containerConstants = window.containerConstants;
  isPudoUpdate = false;
  constructor(props) {
    super(props);

    this.state = {
      showNeedHelpDialog: false,
      openForm: false,
      formIndex: 0,
      showPudoScreen: false,
      landingLayout: false,
      showPudoSavedAlert: false,
      isEmbed: false,
      gettingTrackingDetailsData: false,
    };

    this.setHelpModel = this.setHelpModel.bind(this);
    this.url = this.props.location.search.substring(1);
  }

  async componentDidMount() {
    if(this.props.ciDiy){
      await this.props.getThemeFromDiyConfig(this.url,this.props.trackingDetails);
      this.setState({gettingTrackingDetailsData: true})
    }
    const deviceType = await processUpdateFormService.getDeviceType();
    this.deviceType = deviceType || this.props.trackingDetails.isMobileType;
    if(this.props.preview){
      return;
    }
    // if(this.props.ciDiy){
    //   return ;
    // }
    let params = queryString.parse(this.props.location.search);
    this.setListenerClickCountOnWindow();
    if (params && params.orderId) {
      let res = await processUpdateFormService.getPaymentOrderDetails(
        null,
        params.orderId
      );
      this.state.gettingTrackingDetailsData?this.setState({gettingTrackingDetailsData: false}):''
      if (res && res.url) {
        window.location.replace(location.origin + "/tracking?" + res.url);
      }
    } else {
      await this.props.actions.getTrackingDetails(this.url, this.props.isReturn,this.props.trackingDetails);
      this.state.gettingTrackingDetailsData?this.setState({gettingTrackingDetailsData: false}):''
      const merchantCode = this.props.trackingDetails.vCode;
      this.merchantCode = merchantCode;
      storeClickCountService.storeClickedButtonCount("ciLinkClicked","ciLinkClicked",this.url,this.merchantCode,this.props.isReturn);
    }
    if(window.location.href.indexOf('&pudo=true')!=-1){
      this.setState({showPudoScreen: true})
      if(isEmpty(this.props.pudoPoints)){
      await this.props.actions.getLastMileAndStatusDetail(this.url,this.props.trackingDetails, this.props.isReturn);
      if(this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO 
        && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction  
        && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true 
        &&  isEmpty(this.props.pudoPoints)){
          await this.props.actions.getPudoData(
            this.url,
            this.props.trackingDetails.jobLat,
            this.props.trackingDetails.jobLng,
            this.props.trackingDetails.masterCode
          );
        }
      }
      if(!isEmpty(this.props.pudoPoints))
      this.openPudoScreen();
      
    }
    else if(window.location.href.indexOf('&embed=true')!=-1){
      this.setState({
        isEmbed:true,
      })
    }
  }

  setListenerClickCountOnWindow=() =>{
    // const merchantCode = this.props.trackingDetails.vCode;
    document.addEventListener("click",(event) =>{
    this.props.actions.storeCountsForClickedButton(event,this.url,this.merchantCode,this.props.isReturn);
    });
  }

  getLastMileAndStatusDetail=()=>{
     let url = isEmpty(this.props.url)?this.url:this.props.url;
     if(this.props.trackingDetails &&  this.props.trackingDetails.enableLayoutScreen )
     this.props.actions.getLastMileAndStatusDetail(url,this.props.trackingDetails, this.props.isReturn);
  }

  getRenderScreen = () => {
    if(this.state.gettingTrackingDetailsData){
      return(
        <div className="pt20">
        <Skeleton active />
        </div>
      )
    }
    if (isEmpty(this.props.feedback) && isEmpty(this.props.trackingDetails)) {
      return (
        <div className="clearfix">
          <TableLoader />
        </div>
      );
    } else if (
      isEmpty(this.props.feedback) &&
      isEmpty(this.props.trackingDetails)
    ) {
      return (
        <div style={{ background: "#ffffff" }}>
          <TableLoader />
        </div>
      );
    } else if (!isEmpty(this.props.trackingDetails)) {
      return <div>{this.redirectToScreenType()}</div>;
    } else if (this.props.notFound) {
      return <NotFound />;
    } else {
      return null;
    }
  };
  renderNewLayoutView=(theme)=>{
    if((this.deviceType || this.props.trackingDetails.isMobileType)  && this.props.trackingDetails?.newLayout){
      return( <DeviceInt
        primaryBgColor={theme && theme.primaryBgColor}
        primaryTextColor={theme && theme.primaryTextColor}
        secondaryBgColor={theme && theme.secondaryBgColor}
        secondaryTextColor={theme && theme.secondaryTextColor}
        fontSize={theme && theme.fontSize}
        fontFamily={theme && theme.fontFamily}
        showNeedHelpDialog={this.state.showNeedHelpDialog}
        isReturn={this.props.isReturn}
        openProcessForm={this.openLandingProcessForm}
        openQuickActionForm={this.openProcessForm}
        openPudoScreen={this.openPudoScreen}
        pudoPoints={this.props.pudoPoints}
        pudoTitle={
         this.props.trackingDetails &&
         this.props.trackingDetails.pudo &&
         this.props.trackingDetails.pudo.title
         }
        subLabel={
           this.props.trackingDetails &&
         this.props.trackingDetails.pudo &&
         this.props.trackingDetails.pudo.subLabel
         }
        preview={this.props.preview}
        isMobileType={this.props.trackingDetails.isMobileType}
        openNeedHelpProcessForm={this.openProcessForm}
        newCiData ={theme && theme.newCiData}
        universalTracking={this.props.universalTracking}
      />
    );
    }
    else if(this.deviceType || this.props.trackingDetails.isMobileType){
      return (
        <LayoutScreen
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          secondaryBgColor={theme && theme.secondaryBgColor}
          secondaryTextColor={theme && theme.secondaryTextColor}
          fontSize={theme && theme.fontSize}
          fontFamily={theme && theme.fontFamily}
          showNeedHelpDialog={this.state.showNeedHelpDialog}
          isReturn={this.props.isReturn}
          openProcessForm={this.openLandingProcessForm}
          openQuickActionForm={this.openProcessForm}
          openPudoScreen={this.openPudoScreen}
          pudoPoints={this.props.pudoPoints}
          pudoTitle={
           this.props.trackingDetails &&
           this.props.trackingDetails.pudo &&
           this.props.trackingDetails.pudo.title
           }
          subLabel={
             this.props.trackingDetails &&
           this.props.trackingDetails.pudo &&
           this.props.trackingDetails.pudo.subLabel
           }
          preview={this.props.preview}
          isMobileType={this.props.trackingDetails.isMobileType}
          openNeedHelpProcessForm={this.openProcessForm}
        />
      );
    }
    else if (this.props.trackingDetails?.newLayout){
      let newCiData = theme?.newCiData;
      return (
        <DesktopInt 
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          secondaryBgColor={theme && theme.secondaryBgColor}
          secondaryTextColor={theme && theme.secondaryTextColor}
          fontSize={theme && theme.fontSize}
          fontFamily={theme && theme.fontFamily}
          showNeedHelpDialog={this.state.showNeedHelpDialog}
          isReturn={this.props.isReturn}
          openProcessForm={this.openLandingProcessForm}
          openQuickActionForm={this.openProcessForm}
          openPudoScreen={this.openPudoScreen}
          pudoPoints={this.props.pudoPoints}
          pudoTitle={
           this.props.trackingDetails &&
           this.props.trackingDetails.pudo &&
           this.props.trackingDetails.pudo.title
           }
          subLabel={
             this.props.trackingDetails &&
           this.props.trackingDetails.pudo &&
           this.props.trackingDetails.pudo.subLabel
           }
           preview={this.props.preview}
           openNeedHelpProcessForm={this.openProcessForm}
           newCiData = {newCiData}
           universalTracking={this.props.universalTracking}
        />
      );
    }
    else{
      return (
        <DeskTopView
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          secondaryBgColor={theme && theme.secondaryBgColor}
          secondaryTextColor={theme && theme.secondaryTextColor}
          fontSize={theme && theme.fontSize}
          fontFamily={theme && theme.fontFamily}
          showNeedHelpDialog={this.state.showNeedHelpDialog}
          isReturn={this.props.isReturn}
          openProcessForm={this.openLandingProcessForm}
          openQuickActionForm={this.openProcessForm}
          openPudoScreen={this.openPudoScreen}
          preview={this.props.preview}
        />
        
      );
    }

  }
  checkIfV3=(theme)=>{
    let feedback = this.props.trackingDetails && this.props.trackingDetails.feedback? JSON.parse(this.props.trackingDetails.feedback): false;
    if(this.props.trackingDetails.layoutScreen && !isEmpty(this.props.trackingDetails.layoutScreen) 
      && (feedback && feedback.captureFeedbackV3 && feedback.captureFeedbackV3 == "allowed")){
         return(this.renderNewLayoutView(theme));
    } else {
      return (
        <Feedback
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          secondaryBgColor={theme && theme.secondaryBgColor}
          secondaryTextColor={theme && theme.secondaryTextColor}
          fontSize={theme && theme.fontSize}
          fontFamily={theme && theme.fontFamily}
        />
      );
    }
   
  } 

  redirectToScreenType = (isIndirectScreen) => {
    let theme =
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
    ) {
      document.body.style.fontFamily =
        theme && theme.fontFamily ? theme.fontFamily : "Roboto, sans-serif";
    }
    let newCiData = theme?.newCiData;
    if (
      isIndirectScreen ||
      (this.props.trackingDetails &&
        (this.props.trackingDetails.screen ||
          this.props.feedback ||
          this.props.trackingDetails == "expire"))
    ) {
      /******** here we are checking language direction from country constants ******/
      if (
        this.props.trackingDetails &&
        this.props.trackingDetails.language &&
        countryNamesAndCodes[this.props.trackingDetails.language]
      ) {
        if (
          !window.sessionStorage.languageDirection &&
          countryNamesAndCodes[this.props.trackingDetails.language]
        ) {
          window.sessionStorage.setItem(
            "languageDirection",
            countryNamesAndCodes[this.props.trackingDetails.language]
          );
          location.reload();
          return null;
        } else if (
          window.sessionStorage.languageDirection != null &&
          window.sessionStorage.languageDirection !=
            countryNamesAndCodes[this.props.trackingDetails.language]
        ) {
          window.sessionStorage.setItem(
            "languageDirection",
            countryNamesAndCodes[this.props.trackingDetails.language]
          );
          location.reload();
          return null;
        } else if (
          window.sessionStorage.languageDirection != null &&
          window.sessionStorage.languageDirection ==
            countryNamesAndCodes[this.props.trackingDetails.language]
        ) {
          // do no remove this condition
        }
      } else if (
        this.props.trackingDetails &&
        (!this.props.trackingDetails.language ||
          isEmpty(this.props.trackingDetails.language) ||
          this.props.trackingDetails.language == null)
      ) {
        if (
          window.sessionStorage.languageDirection &&
          window.sessionStorage.languageDirection != "ltr"
        ) {
          window.sessionStorage.setItem("languageDirection", "ltr");
          location.reload();
          return null;
        }
      }

      let languageData = this.props.trackingDetails.language
        ? containerConstantsService.getInstance(
            this.props.trackingDetails.language
          )
        : containerConstantsService.getInstance("en");
      window.containerConstants = languageData;
      if (this.props.feedback) {
        return (
          <Feedback
            primaryBgColor={theme && theme.primaryBgColor}
            primaryTextColor={theme && theme.primaryTextColor}
            secondaryBgColor={theme && theme.secondaryBgColor}
            secondaryTextColor={theme && theme.secondaryTextColor}
            fontSize={theme && theme.fontSize}
            fontFamily={theme && theme.fontFamily}
          />
        );
      } else if (
        this.props.trackingDetails &&
        this.props.trackingDetails == "expire"
      ) {
        return <Expire fontSize={theme && theme.fontSize} />;
      }
      switch (this.props.trackingDetails.screen) {
        case "formScreen":
          return (
            <ProcessUpdateForm
              formIndex={0}
              isReturn={this.props.isReturn}
            />
          );
        case "eventScreen":
          return <EventScreen />;
        case "liveTracking":
          return <LiveTracking />;
        case "feedbackScreen":
          return this.checkIfV3(theme);
        case "layoutScreen":
          this.getLastMileAndStatusDetail();
          // if(this.props.ciDiy){
          //   this.props.getThemeFromDiyConfig(this.url,this.props.trackingDetails);
          // }
          return (
            <LayoutScreen
              primaryBgColor={theme && theme.primaryBgColor}
              primaryTextColor={theme && theme.primaryTextColor}
              secondaryBgColor={theme && theme.secondaryBgColor}
              secondaryTextColor={theme && theme.secondaryTextColor}
              fontSize={theme && theme.fontSize}
              fontFamily={theme && theme.fontFamily}
              showNeedHelpDialog={this.state.showNeedHelpDialog}
              isReturn={this.props.isReturn}
              openProcessForm={this.openLandingProcessForm}
              openQuickActionForm={this.openProcessForm}
              openPudoScreen={this.openPudoScreen}
              pudoPoints={this.props.pudoPoints}
              pudoTitle={
               this.props.trackingDetails &&
               this.props.trackingDetails.pudo &&
               this.props.trackingDetails.pudo.title
               }
              subLabel={
                 this.props.trackingDetails &&
               this.props.trackingDetails.pudo &&
               this.props.trackingDetails.pudo.subLabel
               }
               preview={this.props.preview}
               isMobileType={this.props.trackingDetails.isMobileType}
            />
          );
          case "OrderDeviceView":
            this.getLastMileAndStatusDetail();
            return (
              this.renderOrdeLevelMobileView(theme)
              );

        case "DeskTopView":
          this.getLastMileAndStatusDetail();
          return (
            <DeskTopView
              primaryBgColor={theme && theme.primaryBgColor}
              primaryTextColor={theme && theme.primaryTextColor}
              secondaryBgColor={theme && theme.secondaryBgColor}
              secondaryTextColor={theme && theme.secondaryTextColor}
              fontSize={theme && theme.fontSize}
              fontFamily={theme && theme.fontFamily}
              showNeedHelpDialog={this.state.showNeedHelpDialog}
              isReturn={this.props.isReturn}
              openProcessForm={this.openLandingProcessForm}
              openQuickActionForm={this.openProcessForm}
              openPudoScreen={this.openPudoScreen}
              preview={this.props.preview}
            />
            
          );
         case 'OrderDeskView' :
          this.getLastMileAndStatusDetail();
          return (this.renderOrderLevelDesktopView(theme))
          
          case 'NewDesktopLayout':
              this.getLastMileAndStatusDetail();
          return (
            <DesktopInt
              primaryBgColor={theme && theme.primaryBgColor}
              primaryTextColor={theme && theme.primaryTextColor}
              secondaryBgColor={theme && theme.secondaryBgColor}
              secondaryTextColor={theme && theme.secondaryTextColor}
              fontSize={theme && theme.fontSize}
              fontFamily={theme && theme.fontFamily}
              showNeedHelpDialog={this.state.showNeedHelpDialog}
              isReturn={this.props.isReturn}
              openProcessForm={this.openLandingProcessForm}
              openQuickActionForm={this.openProcessForm}
              openPudoScreen={this.openPudoScreen}
              preview={this.props.preview}
              openNeedHelpProcessForm={this.openProcessForm}
              newCiData ={newCiData}
            />
          );
          case 'NewDeviceLayout':
            this.getLastMileAndStatusDetail();
            return (
              <DeviceInt
                primaryBgColor={theme && theme.primaryBgColor}
                primaryTextColor={theme && theme.primaryTextColor}
                secondaryBgColor={theme && theme.secondaryBgColor}
                secondaryTextColor={theme && theme.secondaryTextColor}
                fontSize={theme && theme.fontSize}
                fontFamily={theme && theme.fontFamily}
                showNeedHelpDialog={this.state.showNeedHelpDialog}
                isReturn={this.props.isReturn}
                openProcessForm={this.openLandingProcessForm}
                openQuickActionForm={this.openProcessForm}
                openPudoScreen={this.openPudoScreen}
                pudoPoints={this.props.pudoPoints}
                pudoTitle={
                 this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.title
                 }
                subLabel={
                   this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.subLabel
                 }
                 preview={this.props.preview}
                 isMobileType={this.props.trackingDetails.isMobileType}
                 openNeedHelpProcessForm={this.openProcessForm}
                 newCiData = {newCiData}
              />
            );


        default:
          return <NotFound />;
      }
    } else if (
      this.props.trackingDetails &&
      this.props.trackingDetails == "expire"
    ) {
      return <Expire fontSize={theme && theme.fontSize} />;
    } else {
      return (
        <NotFound
          primaryBgColor={theme && theme.primaryBgColor}
          primaryTextColor={theme && theme.primaryTextColor}
          fontSize={theme && theme.fontSize}
        />
      );
    }
  };

  renderOrderLevelDesktopView(theme) {
    if(this.props.trackingDetails?.newLayout){
      return (
      <NewDesktopViewOrder
      primaryBgColor={theme && theme.primaryBgColor}
      primaryTextColor={theme && theme.primaryTextColor}
      secondaryBgColor={theme && theme.secondaryBgColor}
      secondaryTextColor={theme && theme.secondaryTextColor}
      fontSize={theme && theme.fontSize}
      fontFamily={theme && theme.fontFamily}
      showNeedHelpDialog={this.state.showNeedHelpDialog}
      isReturn={this.props.isReturn}
      openProcessForm={this.openLandingProcessForm}
      openQuickActionForm={this.openProcessForm}
      openPudoScreen={this.openPudoScreen}
      preview={this.props.preview}
      universalTracking={this.props.universalTracking}
      />)
    }
    else {
      return (
      <DesktopViewOrder
        primaryBgColor={theme && theme.primaryBgColor}
        primaryTextColor={theme && theme.primaryTextColor}
        secondaryBgColor={theme && theme.secondaryBgColor}
        secondaryTextColor={theme && theme.secondaryTextColor}
        fontSize={theme && theme.fontSize}
        fontFamily={theme && theme.fontFamily}
        showNeedHelpDialog={this.state.showNeedHelpDialog}
        isReturn={this.props.isReturn}
        openProcessForm={this.openLandingProcessForm}
        openQuickActionForm={this.openProcessForm}
        openPudoScreen={this.openPudoScreen}
        preview={this.props.preview}
      />
      )}
  }
  renderOrdeLevelMobileView(theme){
    if(this.props.trackingDetails?.newLayout){
      return(
      <NewMobileViewOrder
                primaryBgColor={theme && theme.primaryBgColor}
                primaryTextColor={theme && theme.primaryTextColor}
                secondaryBgColor={theme && theme.secondaryBgColor}
                secondaryTextColor={theme && theme.secondaryTextColor}
                fontSize={theme && theme.fontSize}
                fontFamily={theme && theme.fontFamily}
                showNeedHelpDialog={this.state.showNeedHelpDialog}
                isReturn={this.props.isReturn}
                openProcessForm={this.openLandingProcessForm}
                openQuickActionForm={this.openProcessForm}
                openPudoScreen={this.openPudoScreen}
                pudoPoints={this.props.pudoPoints}
                pudoTitle={
                 this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.title
                 }
                subLabel={
                   this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.subLabel
                 }
                 preview={this.props.preview}
                 isMobileType={this.props.trackingDetails.isMobileType}
                 universalTracking={this.props.universalTracking}
              />
      )
    }
    else{
      return (
        <MobileViewOrder
                primaryBgColor={theme && theme.primaryBgColor}
                primaryTextColor={theme && theme.primaryTextColor}
                secondaryBgColor={theme && theme.secondaryBgColor}
                secondaryTextColor={theme && theme.secondaryTextColor}
                fontSize={theme && theme.fontSize}
                fontFamily={theme && theme.fontFamily}
                showNeedHelpDialog={this.state.showNeedHelpDialog}
                isReturn={this.props.isReturn}
                openProcessForm={this.openLandingProcessForm}
                openQuickActionForm={this.openProcessForm}
                openPudoScreen={this.openPudoScreen}
                pudoPoints={this.props.pudoPoints}
                pudoTitle={
                 this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.title
                 }
                subLabel={
                   this.props.trackingDetails &&
                 this.props.trackingDetails.pudo &&
                 this.props.trackingDetails.pudo.subLabel
                 }
                 preview={this.props.preview}
                 isMobileType={this.props.trackingDetails.isMobileType}
              />
      )
    }
  }

  openProcessForm = (index) => (event) => {
    this.setState({
      openForm: true,
      formIndex: index,
    });
  };
  openLandingProcessForm = (index) => {
    this.setState({
      openForm: true,
      formIndex: index,
      landingLayout: true,
    });
  };
  closeProcessForm = () => {
    this.setState({
      openForm: false,
      formIndex: 0,
      showNeedHelpDialog: false,
    });
  };

  openPudoScreen = (e) => {
    this.setState({
      showPudoScreen: true,
      showNeedHelpDialog: false,
    });
  };

  isMobileView = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.screen == "feedbackScreen"
    ) {
      return true;
    } else if (this.deviceType == true) {
      return true;
    }
    return false;
  };

  setHelpModel = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.pudo &&
      this.props.trackingDetails.pudo.pudoScreen === true
    ) {
      if (isEmpty(this.props.pudoPoints)) {
        this.props.actions.getPudoData(
          this.url,
          this.props.trackingDetails.jobLat,
          this.props.trackingDetails.jobLng,
          this.props.trackingDetails.masterCode
        );
      }
    }
    this.setState({ showNeedHelpDialog: true });
  };

  closeHelpModal = () => {
    this.setState({ showNeedHelpDialog: false });
  };
  getHelpSection = (theme) => {
    return (
      <HelpSection
        showNeedHelpDialog={this.state.showNeedHelpDialog}
        setHelpSection={this.closeHelpModal}
        trackingDetails={this.props.trackingDetails}
        openProcessForm={this.openProcessForm}
        screen={this.props.trackingDetails.screen}
        pudoPoints={this.props.pudoPoints}
        openPudoScreen={this.openPudoScreen}
        pudoTitle={
          this.props.trackingDetails &&
          this.props.trackingDetails.pudo &&
          this.props.trackingDetails.pudo.title
        }
        subLabel={
          this.props.trackingDetails &&
          this.props.trackingDetails.pudo &&
          this.props.trackingDetails.pudo.subLabel
        }
      />
    );
  };
  closePudoSavedAlert=()=>{
    setTimeout(() => {
      this.setState({showPudoSavedAlert:false})
    }, 3000);
  }
  pudoUpdated = () => {
    this.setState({ showPudoScreen: false, showNeedHelpDialog: false, showPudoSavedAlert:true });
    
  };

  renderSection = (theme) => {
    let quickAction= this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction || false;
    let showNeedHelp= this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.showNeedHelp || false;
    if (this.props.newCiPrototype) {
      this.getLastMileAndStatusDetail();
      if (this.deviceType) {
        return (
          <MobileViewPrototype 
          url={this.url}/>
        )
      }
      else {
        return (
          <DesktopViewPrototype
            url={this.url}
          />
        )
      }
    }
    if (this.state.openForm) {
      return (
        <ProcessUpdateForm
          formIndex={this.state.formIndex}
          back={!this.state.landingLayout}
          backScreen={this.closeProcessForm}
          landingLayout={this.state.landingLayout}
          isReturn={this.props.isReturn}
          calledFromMainScreen={true}
        />
      );
    } else if (this.state.showPudoScreen) {
       if(this.props.trackingDetails && this.props.trackingDetails.screen && this.props.trackingDetails.screen=='DeskTopView'){
        return (
          <AvailableLocationV3Desktop
            pudoPoints={this.props.pudoPoints}
            pudoConfig={this.props.trackingDetails.pudo}
            onBackClick={() =>
              this.setState({ showPudoScreen: false, showNeedHelpDialog: !quickAction })
            }
            updatedShop={this.pudoUpdated}
            primaryBgColor={theme && theme.primaryBgColor}
            primaryTextColor={theme && theme.primaryTextColor}
            secondaryBgColor={theme && theme.secondaryBgColor}
            secondaryTextColor={theme && theme.secondaryTextColor}
          />
        );
       } else {
        return (
          <AvailableLocationV3
            pudoPoints={this.props.pudoPoints}
            pudoConfig={this.props.trackingDetails.pudo}
            onBackClick={() =>
              this.setState({ showPudoScreen: false, showNeedHelpDialog: !quickAction })
            }
            updatedShop={this.pudoUpdated}
            primaryBgColor={theme && theme.primaryBgColor}
            primaryTextColor={theme && theme.primaryTextColor}
            secondaryBgColor={theme && theme.secondaryBgColor}
            secondaryTextColor={theme && theme.secondaryTextColor}
          />
        );
       }
    } else if (
      this.props.trackingDetails &&
      this.props.trackingDetails == "expire"
    ) {
      return this.getRenderScreen();
    } else if (
      this.props.trackingDetails &&
      this.props.trackingDetails.screen &&
      this.props.trackingDetails.screen == "formScreen"
    ) {
      return this.getRenderScreen();
    } else if (
      this.props.trackingDetails &&
      !isEmpty(this.props.trackingDetails)
    ) {
      if (
        window.location.href.indexOf("&embed=true") != -1 &&
        this.props.trackingDetails.screen &&
        this.props.trackingDetails.screen == "liveTracking"
      ) {
        return (
          <>
        {this.redirectToScreenType()}
        {this.state.showPudoSavedAlert?
            <div className="pudo-save-alert">
            <MessageSnackBar
            message={"Store updated successfully"}
            />
            </div>
          :null
          }
          </>
        );
      } else {
        return (
          <div>
            {(this.state.isEmbed == false && !this.props.universalTracking)?
            <Header
              mobileView={this.isMobileView()}
              heading={this.props.trackingDetails.companyName}
              logo={this.props.trackingDetails.companyLogo}
              ctaColorWhite={theme && theme.ctaColorWhite}
              ctaFontColor={theme && theme.ctaFontColor}
              needHelp={true}
              setHelpModel={this.setHelpModel}
              deviceType={this.deviceType}
              isV3={this.props.trackingDetails.layoutScreen && !isEmpty(this.props.trackingDetails.layoutScreen)}
              quickAction={quickAction}
              showNeedHelp ={showNeedHelp}
              isMobileType={this.props.trackingDetails.isMobileType}
              merchantCode={this.merchantCode}
              url={this.url}
              isReturn={this.props.isReturn}
            />
            :""}
            {(!quickAction || showNeedHelp) && this.getHelpSection(theme)}
            {this.getRenderScreen()}
            {this.state.showPudoSavedAlert?
              <div className="pudo-save-alert">
              <MessageSnackBar
              message={"Store updated successfully"}/>
              </div>
              :null
            }
            {/* <CustomScreen/> */}
          </div>
        );
      }
    } else {
      return (
        <div style={{ background: "#ffffff" }}>
          <TableLoader />
        </div>
      );
    }
  };


  getTheme = () => {
    let themeData =
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;

    if(themeData) {
      return {
        "--primary-bg-color": themeData.primaryBgColor,
        "--secondary-bg-color": themeData.secondaryBgColor,
        "--primary-text-color": themeData.primaryTextColor,
        "--secondary-text-color": themeData.secondaryTextColor,
      }
    }
  }

  render() {
    let theme =
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;
    if(this.state.showPudoSavedAlert){this.closePudoSavedAlert()}    
    return (
      <div style={this.getTheme()}>
        <MyContext.Provider
          value={{
            primaryBgColor: theme && theme.primaryBgColor,
            primaryTextColor: theme && theme.primaryTextColor,
            secondaryBgColor: theme && theme.secondaryBgColor,
            secondaryTextColor: theme && theme.secondaryTextColor,
            fontSize: theme && theme.fontSize,
            fontFamily: theme && theme.fontFamily,
            useAsPrimaryColour:theme && theme.useAsPrimaryColour,
            headerColor:theme && theme.headerColor
          }}
        >
          <Fragment>
            {this.renderSection(theme)}
          </Fragment>
        </MyContext.Provider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    notFound: state.customerInteractionReducer.notFound,
    feedback: state.customerInteractionReducer.feedback,
    url:state.customerInteractionReducer.url,
    pudoPoints: state.pudoReducer.pudoPoints,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...CustomerInteractionAction, getPudoData ,getThemeFromDiyConfig},
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
